import React, { useEffect, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { toast } from "react-toastify";
import { ToastContainer } from "react-toastify";
import { fetcher } from "../api/ApiHook";
import { USER_REGISTRATION } from "../api/ApiConstants";
import Loader from "../Loader";
import CountrySelect from "../Form/CountrySelect";

const Register = () => {
  const emailRegex = /^[\w\.-]+@[a-zA-Z\d\.-]+\.[a-zA-Z]{2,6}$/;

  const [showPassword, setShowPassword] = useState(false);
  const [recaptchaToken, setRecaptchaToken] = useState("");
  const [recaptchaExpired, setRecaptchaExpired] = useState(false);
  const [loading, setLoading] = useState(false);

  const [formData, setFormData] = useState({
    name: "",
    company_name: "",
    country: null,
    phone: "",
    email: "",
    password: "",
  });

  const [isFormValid, setIsFormValid] = useState(false);
  const [params, setParams] = useState({});
  const [filteredParams, setFilteredParams] = useState({});

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    // Initialize an object to hold the final decoded params
    const paramsObj = {};

    urlParams.forEach((value, key) => {
      const decodedKey = decodeURIComponent(key);
      const decodedValue = decodeURIComponent(value);

      // Check if the key is something like plateform[0] or plateform[1]
      const arrayKeyMatch = decodedKey.match(/^(.*)\[(\d+)\]$/);
      if (arrayKeyMatch) {
        const baseKey = arrayKeyMatch[1];
        if (!paramsObj[baseKey]) {
          paramsObj[baseKey] = [];
        }
        paramsObj[baseKey].push(decodedValue);
      } else {
        paramsObj[decodedKey] = decodedValue;
      }
    });

    // Set the original params state
    setParams(paramsObj);

    // Filter out specific keys and set the filteredParams state
    const filteredObj = Object.fromEntries(
      Object.entries(paramsObj).filter(
        ([key]) =>
          !["company_name", "invited_user_email", "user_email"].includes(key)
      )
    );

    setFilteredParams(filteredObj);
  }, []);

  const onRecaptchaExpired = () => {
    setRecaptchaToken(null);
    setRecaptchaExpired(true);
  };

  const onRecaptchaChange = (token) => {
    setRecaptchaToken(token);
    setRecaptchaExpired(false);
  };

  useEffect(() => {
    if (params?.company_name) {
      setFormData({
        ...formData,
        company_name: params.company_name,
        email: params.invited_user_email,
      });
    }
  }, [params]);

  useEffect(() => {
    const { name, company_name, country, email, password } = formData;
    setIsFormValid(
      name.trim() !== "" &&
        company_name.trim() !== "" &&
        email.trim() !== "" &&
        emailRegex.test(email) &&
        country !== null &&
        country !== "" &&
        country !== undefined &&
        password.trim() !== "" &&
        recaptchaToken !== "" &&
        !recaptchaExpired
    );
  }, [formData, recaptchaToken, recaptchaExpired]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!isFormValid) {
      return;
    }
    formData.domain = "tasc360";
    let method = "POST";
    let payload = JSON.stringify({
      ...formData,
      country_code: formData.country === 'UAE' ? 'UAE' : 'KSA',
      ...(filteredParams?.profile_id && filteredParams),
    });
    try {
      setLoading(true);
      const response = await fetcher(USER_REGISTRATION, method, payload);
      if (response.status) {
        toast.success(response.message);
      } else {
        toast.error(response.message);
      }
    } catch (err) {
      console.log(err, "error");
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleSubmit(e);
    }
  };

  return (
    <div className="inc-auth-container">
      <div className="tw-container tw-mx-auto tw-min-h-screen tw-flex tw-items-center tw-justify-center tw-text-center">
        {loading && <Loader />}
        <div className="cover-container tw-w-full tw-h-full tw-p-3">
          <div className="form-container tw-max-w-[440px] tw-bg-white tw-mx-auto">
            <div>
              <img
                src="/newLogo.svg"
                alt="TASC logo"
                className="tw-mx-auto tw-max-w-[220px]"
              />
              <div className="tw-text-[24px] ff-ws tw-mb-2 text-inc-tundora tw-font-[600]">
                Sign Up
              </div>
            </div>
            <form
              onSubmit={handleSubmit}
              className="form-auth tw-w-full tw-mx-auto tw-flex tw-flex-col tw-gap-[15px]"
              id="registration-custom-form"
              onKeyDown={handleKeyDown}
            >
              <input
                type="text"
                placeholder="Name*"
                name="name"
                value={formData.name}
                className="form-control"
                style={{ background: "#e8f0fe" }}
                onChange={handleInputChange}
              />
              <input
                type="text"
                placeholder="Company name*"
                name="company_name"
                className="form-control"
                style={{
                  background: params?.company_name ? "#ececec" : "#e8f0fe",
                  color: params?.company_name ? "darkgrey" : "",
                }}
                value={formData.company_name || params?.company_name}
                onChange={handleInputChange}
                disabled={params?.company_name}
              />
              {/* <div className="tw-w-100"> */}
              <CountrySelect formData={formData} setFormData={setFormData} />
              {/* </div> */}
              <input
                type="text"
                placeholder="+966 011 XXX XXXX"
                name="phone"
                className="form-control"
                style={{ background: "#e8f0fe" }}
                value={formData.phone}
                onChange={handleInputChange}
              />
              <input
                type="text"
                placeholder="Email*"
                name="email"
                className="form-control"
                style={{
                  background: params?.invited_user_email
                    ? "#ececec"
                    : "#e8f0fe",
                  color: params?.invited_user_email ? "darkgrey" : "",
                }}
                value={formData.email || params?.invited_user_email}
                onChange={handleInputChange}
                disabled={params?.invited_user_email}
              />
              <div className="input-group flex-nowrap" id="password-filled">
                <input
                  className="tw-w-full form-control !tw-rounded-l-[50px] !tw-rounded-r-[0px]"
                  id="input-password"
                  type={showPassword ? "text" : "password"}
                  placeholder="Password"
                  style={{ background: "#e8f0fe" }}
                  name="password"
                  value={formData.password}
                  onChange={handleInputChange}
                />
                <span
                  className="tw-text-xl encrypted tw-py-[5px] tw-px-[10px] tw-border tw-border-[#dee2e6]"
                  style={{ cursor: "pointer", borderRadius: "0 50px 50px 0" }}
                >
                  {showPassword ? (
                    <i
                      class="bi bi-eye-fill"
                      onClick={() => setShowPassword(false)}
                    ></i>
                  ) : (
                    <i
                      class="bi bi-eye-slash-fill"
                      onClick={() => setShowPassword(true)}
                    ></i>
                  )}
                </span>
              </div>
              <div className="tw-w-full tw-flex tw-justify-center">
                <ReCAPTCHA
                  sitekey="6LdmFMQpAAAAAGwLfYZopzckKXOu0obCtpHW0obV"
                  onChange={onRecaptchaChange}
                  onExpired={onRecaptchaExpired}
                />
              </div>
              <button
                className="btn btn-to-link btn-secondary btn-gradiant tw-mt-6 tw-flex tw-items-center bg-inc-orange"
                type="submit"
                style={{
                  opacity: !isFormValid ? "0.65" : "",
                }}
                disabled={!isFormValid}
              >
                <span className="tw-font-[700]">Sign Up</span>
                <span className="icon-btn_track" style={{ marginLeft: "10px" }}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-arrow-right-circle-fill"
                    viewBox="0 0 16 16"
                  >
                    <path d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0M4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5z" />
                  </svg>
                </span>
              </button>
            </form>
            <div className="tw-mt-4">
              <a
                href="/"
                className="tw-text-[13px] tw-underline text-inc-tundora"
              >
                Already have an Account?
              </a>
            </div>
            <div
              className="tw-mt-3 tw-text-[13px] ff-ws"
              style={{ color: "grey" }}
            >
              Powered by TASC Outsourcing®
            </div>
          </div>
        </div>
        <ToastContainer position="bottom-right" />
      </div>
    </div>
  );
};

export default Register;
