import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  getBrowserInfo,
  getUserDataFromCookie,
  isUserVerified,
  setUserDataCookie,
} from "./utils/helper";
import { fetcher } from "./api/ApiHook";
import { toast, ToastContainer } from "react-toastify";
import Loader from "./Loader";
import { Credentials } from "../icons/Icons";
import { Mail, Smartphone } from "react-feather";
import OtpInput from "./OtpInput";
import { SEND_OTP, VERIFY_OTP } from "./api/ApiConstants";

const TwoFactor = () => {
  const [twoFactor, setTwoFactor] = useState(true);
  const [animation, setAnimation] = useState(true);
  const [verificationType, setVerificationType] = useState("");
  const [recipient, setRecipient] = useState("");
  const [verifyAccount, setVerifyAccount] = useState(false);
  const [otp, setOtp] = useState(Array(6).fill(""));
  const [error, setError] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const [loadingConfirm, setLoadingConfirm] = useState(false);
  const inputRefs = useRef([]);
  const navigate = useNavigate();

  const handleChangeOtp = (value, index) => {
    if (value.match(/^\d$/)) {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);

      if (index < 5) {
        inputRefs.current[index + 1].focus();
      }
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace") {
      const newOtp = [...otp];

      if (otp[index] === "" && index > 0) {
        inputRefs.current[index - 1].focus();
      } else if (otp[index] !== "") {
        newOtp[index] = "";
        setOtp(newOtp);
      }
    }
  };

  const checkUserDataIsAlreadySetInCookieOrNot = (browserInfo) => {
    let data = getUserDataFromCookie();
    if (data === null) {
      data = [];
      data.push({
        userId: localStorage.getItem("userId"),
        user: [
          {
            browserName: browserInfo.browser,
            system: browserInfo.os,
          },
        ],
      });
    } else if (data.userId == sessionStorage.getItem("userId")) {
      data.user.push({
        browserName: browserInfo.browser,
        system: browserInfo.os,
      });
    } else {
      data.push({
        userId: sessionStorage.getItem("userId"),
        user: [
          {
            browserName: browserInfo.browser,
            system: browserInfo.os,
          },
        ],
      });
    }
    setUserDataCookie(data);
  };

  let browserInfo = getBrowserInfo();
  const checkTwoFactorAuthenticated = () => {
    checkUserDataIsAlreadySetInCookieOrNot(browserInfo);
    let origin = window.location.origin + "/";
    if (
      sessionStorage.getItem("redirectUrl") !== null ||
      sessionStorage.getItem("redirectUrl") !== undefined
    ) {
      if (origin == sessionStorage.getItem("redirectUrl")) {
        setTimeout(() => {
          navigate("/tasc360/home");
          sessionStorage.removeItem("redirectUrl");
        }, 2000);
      } else {
        setTimeout(() => {
          window.location.href = sessionStorage.getItem("redirectUrl");
          sessionStorage.removeItem("redirectUrl");
        }, 2000);
      }
    } else {
      setTimeout(() => {
        navigate("/");
      }, 2000);
    }
  };

  const handleSubmit = async () => {
    let valid = true;
    otp.forEach((item) => {
      if (item === "") {
        valid = false;
      }
    });

    if (!valid) {
      setError(true);
      return;
    } else {
      setError(false);
    }
    setLoadingConfirm(true);
    try {
      const res = await fetcher(
        VERIFY_OTP,
        "POST",
        JSON.stringify({
          code: otp.join(""),
          recipient: recipient,
        })
      );
      if (res.status) {
        setErrMsg("");
        setError(false);
        toast.success(res.message);
        checkTwoFactorAuthenticated();
      } else {
        setErrMsg(
          "This is a wrong or expired code. Try to resend another code."
        );
        setError(true);
      }
    } catch (error) {
      console.log("error");
    } finally {
      setLoadingConfirm(false);
    }
  };

  const sendVerificationMessage = async (type, value) => {
    try {
      const res = await fetcher(
        SEND_OTP,
        "POST",
        JSON.stringify({ channel: type, recipient: value })
      );
      console.log("res", res);
    } catch (error) {
      console.log("err", error);
    }
  };

  useEffect(() => {
    if (
      localStorage.getItem("userToken") == null ||
      localStorage.getItem("userToken") == undefined ||
      localStorage.getItem("userToken") == ""
    ) {
      navigate("/");
    } else if (
      isUserVerified(
        localStorage.getItem("userId"),
        browserInfo.browser,
        browserInfo.os
      )
    ) {
      navigate("/tasc360/home");
    }
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setAnimation(false);
    }, 300);
  }, []);

  return (
    <>
      {loadingConfirm && <Loader />}
      <div
        className="tw-flex tw-flex-col tw-justify-center tw-items-center inc-auth-container"
        style={{ minHeight: "100vh" }}
      >
        {/* <div className="animation-container" style={{ minHeight: "90px" }}>
            <div
              className={`header-heading1 ${
                animation ? "animation-content" : ""
              } ff-ws `}
              style={{
                transition: "transform 1s ease, opacity 2s ease",
                fontSize: "50px",
                fontWeight: "500",
              }}
            >
              Incorpify
            </div>
          </div> */}
        {twoFactor && (
          <div
            className="tw-text-center tw-flex tw-flex-col tw-p-4"
            style={{
              gap: "20px",
              maxWidth: "440px",
              boxShadow: "0 2px 6px #0003",
              background: "#fff",
            }}
          >
            <div className="tw-mx-auto">
              <Credentials width={56} height={56} fill="#005f83" />
            </div>
            <div className="tw-flex tw-flex-col" style={{ gap: "5px" }}>
              <div className="fs-24 tw-font-[700]" style={{ color: "#202223" }}>
                Two-Factor Authentication
              </div>
              <div className="fs-16" style={{ color: "#6D7175" }}>
                Select a Verification method to verify your Account.
              </div>
              <div
                style={{
                  border: "1px solid #DFDFDF",
                  padding: "18px",
                  gap: "10px",
                  borderRadius: "8px",
                  cursor: "pointer",
                }}
                className="tw-flex tw-mt-2 tw-items-center"
                onClick={() => {
                  setTwoFactor(false);
                  setVerifyAccount(true);
                  setVerificationType("email");
                  setRecipient(localStorage.getItem("userEmail"));
                  sendVerificationMessage(
                    "email",
                    localStorage.getItem("userEmail")
                  );
                }}
              >
                <Mail
                  style={{ color: "#005f83", width: "32px", height: "24px" }}
                />
                <div
                  className="tw-flex tw-flex-col tw-text-start"
                  style={{ gap: "5px" }}
                >
                  <div
                    className="fs-18 tw-font-[600]"
                    style={{ color: "#202223" }}
                  >
                    via Email
                  </div>
                  <div style={{ color: "#6D7175" }}>
                    Get a code at{" "}
                    {localStorage.getItem("userEmail")
                      ? localStorage.getItem("userEmail").slice(0, 3) +
                        "***************" +
                        localStorage
                          .getItem("userEmail")
                          .slice(
                            localStorage.getItem("userEmail").length - 9,
                            localStorage.getItem("userEmail").length
                          )
                      : ""}
                  </div>
                </div>
              </div>
              <div
                style={{
                  border: "1px solid #DFDFDF",
                  padding: "18px",
                  gap: "10px",
                  borderRadius: "8px",
                  cursor: "pointer",
                }}
                className="tw-flex tw-mt-2 tw-items-center"
                onClick={() => {
                  setTwoFactor(false);
                  setVerifyAccount(true);
                  setVerificationType("sms");
                  setRecipient(localStorage.getItem("phone"));
                  sendVerificationMessage("sms", localStorage.getItem("phone"));
                }}
              >
                <Smartphone
                  style={{ color: "#005f83", width: "32px", height: "24px" }}
                />
                <div
                  className="tw-flex tw-flex-col tw-text-start"
                  style={{ gap: "5px" }}
                >
                  <div
                    className="fs-18 tw-font-[600]"
                    style={{ color: "#202223" }}
                  >
                    via Mobile Number
                  </div>
                  <div style={{ color: "#6D7175" }}>
                    Get a code at{" "}
                    {localStorage.getItem("phone")
                      ? localStorage.getItem("phone").slice(0, 3) +
                        "********" +
                        localStorage
                          .getItem("phone")
                          .slice(
                            localStorage.getItem("phone").length - 2,
                            localStorage.getItem("phone").length
                          )
                      : ""}
                  </div>
                </div>
                {/* <div
                  className="fs-s"
                  style={{ color: "#EF4444", marginLeft: "auto" }}
                >
                  Unverified
                </div> */}
              </div>
            </div>
          </div>
        )}
        {verifyAccount && (
          <div
            className="tw-flex tw-flex-col tw-items-center tw-text-center tw-p-4"
            style={{
              gap: "20px",
              maxWidth: "440px",
              boxShadow: "0 2px 6px #0003",
              background: "#fff",
            }}
          >
            {verificationType === "email" ? (
              <Mail
                style={{ color: "#005f83", width: "48px", height: "36px" }}
              />
            ) : (
              <Smartphone
                style={{ color: "#005f83", width: "48px", height: "36px" }}
              />
            )}
            <div className="tw-flex tw-flex-col" style={{ gap: "20px" }}>
              <div className="tw-flex tw-flex-col" style={{ gap: "5px" }}>
                <div
                  className="fs-24 tw-font-[700]"
                  style={{ color: "#202223" }}
                >
                  Two-Factor Authentication
                </div>
                <div className="fs-16" style={{ color: "#6D7175" }}>
                  Enter your 6-digit code numbers sent to you at{" "}
                  {verificationType === "email"
                    ? localStorage.getItem("userEmail")
                    : localStorage.getItem("phone")}
                </div>
              </div>
              <div className="tw-flex tw-justify-between">
                {Array(6)
                  .fill("")
                  .map((_, index) => {
                    return (
                      <OtpInput
                        otp={otp}
                        inputRef={(el) => (inputRefs.current[index] = el)}
                        index={index}
                        setOtp={setOtp}
                        handleChangeOtp={handleChangeOtp}
                        handleKeyDown={handleKeyDown}
                        error={error}
                      />
                    );
                  })}
              </div>
              <div className="tw-flex tw-flex-col" style={{ gap: "5px" }}>
                {errMsg && <div style={{ color: "#EF4444" }}>{errMsg}</div>}
                <div style={{ color: "#6D7175" }} className="fs-16">
                  Didn't receive a verification code?
                </div>
                <span
                  className="fs-16 tw-font-[600]"
                  style={{ color: "#005f83", cursor: "pointer" }}
                  onClick={() =>
                    sendVerificationMessage(verificationType, recipient)
                  }
                >
                  Resend Code
                </span>
              </div>
              <div>
                <button
                  style={{
                    background: "#005f83",
                    color: "#fff",
                    borderRadius: "8px",
                    gap: "5px",
                    padding: "10px 20px",
                  }}
                  className={`tw-border-0 tw-flex fs-16 tw-font-[600] tw-items-center tw-w-full tw-justify-center`}
                  onClick={() => handleSubmit()}
                >
                  Verify
                </button>
              </div>
              <span
                className="fs-16 tw-font-[600]"
                style={{ color: "#005f83", cursor: "pointer" }}
                onClick={() => {
                  setVerifyAccount(false);
                  setTwoFactor(true);
                }}
              >
                Try another method
              </span>
            </div>
          </div>
        )}
      </div>
      <ToastContainer position="bottom-right" />
    </>
  );
};

export default TwoFactor;
